import {PolymerElement} from '@polymer/polymer/polymer-element.js';
import {html} from '@polymer/polymer/lib/utils/html-tag.js';

const $_documentContainer = document.createElement('div');
$_documentContainer.setAttribute('id', 'commonTransitionStyles');
$_documentContainer.setAttribute('style', 'display: none;');

$_documentContainer.innerHTML = `
<dom-module id="common-transition-styles">
    <template>
        <style>
            @media print {
                #headerSection,  
                #nsFooter,
                #nsHeader {
                    display: none;
                }
            }

            /* used when you need to freeze scrollng (needed for ns-overlay) */
            .no-scroll,
             .no-scroll body{
                overflow: hidden;
            }

            /* ========= Firefox & Edge/IE Style Bleeding Overrides ========== */

            label.ns-input,
            div.input-field,
            div.input-wrap {
                border: none;
                padding: 0;
                min-height: 1em;
                font-size: 1rem;
            }

            .ns-input.flex-spacer,
            .ns-input.label-row {
                border: none;
                padding: 0;
                font-size: 1rem;
            }

            error-tag.ns-input {
                border: none;
                padding: 0;
                margin: 0;
                min-height: 1rem;
            }

            input.ns-input,
            input.ns-input[type="text"] {
                font-size: 1rem;
                border: var(--global-border);
            }

            input.increment[type="number"] {
                min-height: 20px;
                padding: 0;
            }

            td.ns-line-item {
                border: none;
                padding: 0 1em;
                text-align: right;
            }
            
            /*#mainContainer{*/
                /*min-height: calc(100vh - var(--header-height) - var(--footer-height));*/
            /*}*/

            /* Paper Tooltip Styles */
            paper-tooltip {
              --paper-tooltip: {
                max-width: 320px;
                background-color: white;
                border: 1px solid var(--blackhole);
                font-size: 14px;
                color: var(--blackhole);
                box-shadow: 3px 3px 3px var(--gandalf);
              };
            }

            /* Paper Dropdown Menu Styles */
            ns-paper-dropdown paper-item:hover {
                background: var(--color-interactive-5);
                cursor: pointer;
            }
            
            paper-radio-group .radioSubtitle{
                margin-left: 39px;
                color: var(--steel);
                margin-top: -10px;
            }
            
            /* Signup Styles */
            
            .signincontent{
              max-width:670px;
              padding:60px 100px;
              background-color:white;
            }
            
            .signincontent .reasons-tile {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: #727272;
            }
            
            .signincontent .reasons-tile i {
                width: 2rem;
                height: 2rem;
                display: block;
                padding: 0.25rem;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }
            .signincontent .reasons-tile i.ic-clock {
                background-image: url(src/assets/images/css/ic-clock.svg);
            }
            
            .signincontent .reasons-tile i.ic-track {
                background-image: url(src/assets/images/css/ic-track.svg);
              }
            
              .signincontent .reasons-tile i.ic-review {
                background-image: url(src/assets/images/css/ic-review.svg);
              }
            
              .signincontent .reasons-tile i.ic-recommend {
                background-image: url(src/assets/images/css/ic-recommend.svg);
              }
            
        </style>
    </template>
</dom-module>
`;

document.head.appendChild($_documentContainer);
