import {PolymerElement} from '@polymer/polymer/polymer-element.js';
import {html} from '@polymer/polymer/lib/utils/html-tag.js';

const $_documentContainer = document.createElement('div');
$_documentContainer.setAttribute('id', 'commonAppStyles');
$_documentContainer.setAttribute('style', 'display: none;');

$_documentContainer.innerHTML = `
<dom-module id="common-app-styles">
    <template>
        <style>
            /* Global Base Styles */
            * {
                -webkit-font-smoothing: antialiased;
            }
            html {
                margin: 0;
            }
            body {
                font-family: var(--font-family-primary);
                background-size: cover;
                color: var(--color-interactive-8);
                overflow-x: hidden;
                margin: 0;
            }

            /* @apply rules are not properly applied in Firefox and Edge so additional styles are added with the @apply after them */
            h1 {
                font-family:    var(--font-family-primary);
                font-weight:    var(--font-weight-thin);
                font-size:      22px;
                line-height:    23px;
                @apply --title-large-mobile;
                color: var(--color-primary-text);
            }
            h2 {
                font-family:    var(--font-family-primary);
                font-weight:    var(--font-weight-regular);
                font-size:      28px;
                line-height:    34px;
                @apply --title-medium;
                color: var(--color-primary-text);
            }
            h3 {
                font-family:    var(--font-family-primary);
                font-weight:    var(--font-weight-regular);
                font-size:      22px;
                line-height:    20px;
                @apply --title;
                color: var(--color-primary-text);
            }
            h4 {
                font-family:    var(--font-family-primary);
                font-weight:    var(--font-weight-regular);
                font-size:      18px;
                line-height:    20px;
                @apply --title-small;
                color: var(--color-primary-text);
            }
            h5 {
                font-family:    var(--font-family-primary);
                font-weight:    var(--font-weight-regular);
                font-size:      16px;
                line-height:    18px;
                @apply --subtitle;
                color: var(--color-primary-text);
            }
            h6 {
                font-family:    var(--font-family-primary);
                font-weight:    var(--font-weight-regular);
                font-size:      13px;
                line-height:    22px;
                @apply --subtitle-small;
                color: var(--color-primary-text);
            }
            p {
                font-family:    var(--font-family-primary);
                font-weight:    var(--font-weight-regular);
                font-size:      16px;
                line-height:    20px;
                @apply --p-medium;
                color: var(--color-primary-text);
                margin: 0;
            }
            li {
                font-family:    var(--font-family-primary);
                font-weight:    var(--font-weight-regular);
                font-size:      16px;
                line-height:    20px;
                @apply --p-medium;
                color: var(--color-primary-text);
            }
            a.base,
            .a-base {
                color: var(--color-primary-text);
                /*@apply --a-base;*/
            }
            a.base:hover,
            .a-base:hover {
                /*text-decoration: underline;*/
            }
            a.base,
            .a-base{
                @apply --a-base;
                position: relative;
                text-decoration: none !important;
                display: inline-block;
            }
            a.base::before,
            .a-base::before{
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                bottom: -1px;
                width: 100%;
                height: 1px;
                background-color: currentColor;
                opacity: 0;
                visibility: hidden;
                transition: var(--global-transition);
            }
            a.base:hover::before,
            .a-base:hover::before{
                opacity: 1;
                visibility: visible;
            }

            fieldset {
                margin: 0;
                padding: 0;
                border: none;
            }

            legend {
                @apply --base-h2;
                padding: 0;
            }
            
            input::-ms-clear {
                display: none;
            }

            code {
                margin: 0 5px;
                padding: 1px 3px;
                background: var(--color-background-1);
                color: var(--color-error);
                border: 1px solid var(--color-tertiary);
                border-radius: 5px;
            }
            marked-element code {
                margin: initial;
                padding: initial;
                /* background: intitial; */
                color: initial;
                border: initial;
                border-radius: initial;
            }
            /* These are specific to the iron-icon icons, and 2x is twice as large (40), 3x is three times as large (60 etc) */
            .icon-2x{
                width:40px;
                height:40px;
            }
            
            .icon-3x{
                width:60px;
                height:60px;
            }
            
            .icon-4x{
                width:80px;
                height:80px;
            }
            
            .icon-5x{
                width:100px;
                height:100px;
            }
            
            
            /* Removing skype link from IE in Contact Information, specifically for Windows Machines */
            img[title *= 'Call'] {
                display: none !important;
            }
            
            /* To invoke number-keypad on iOS and Andriod devices */
            input[type="tel"] {
            -moz-appearance: textfield;
            }
            input[type="tel"]::-webkit-inner-spin-button,
            input[type="tel"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }
            input[type="tel"]:hover,
            input[type="tel"]:focus {
            -moz-appearance: number-input;
            }
            /* End number-keypad */
            :-moz-ui-invalid:not(output) {
                box-shadow: none;
            }

            /* used when you need to freeze scrollng (needed for ns-overlay) */
            .no-scroll,
             .no-scroll body{
                overflow: hidden;
            }
            
            .no-padding {
                padding: 0;
                height: 0;
            }
            
            .no-margin {
                margin: 0;
            }
            
            .list-style-none {
                list-style-type: none;
            }
            
            .pull-left {
                float: left;
            }
            .pull-right {
                float: right;
            }

            /* best used for form layout, place on the parent */
            .rhythm-y-20-adaptive > *,
            .rhythm-y-20 > * {
                margin-bottom: 20px;
            }
            .rhythm-y-20-adaptive:last-child,
            .rhythm-y-20:last-child {
                margin-bottom: 0;
            }
            
            .responsive-container {
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                padding: 0 15px;
            }
            
            .variant-dropdown__swatch{
				height: 20px;
				width: 20px;
				border-radius: 50%;
				margin-right: 10px;
				display: none;
			}
			.variant-dropdown__swatch[style*="#"],
			.variant-dropdown__swatch[style*="rgb"]{
				display: inline-block;
			}

            /* ========== Polymer Element Overrides ========== */
            paper-toast, paper-toast[type="information"] {
                border-top: 2px solid var(--color-information);
                --paper-toast-background-color: #E0F2FF;
                --paper-toast-color: var(--color-primary-text);
                max-width: 500px;
            }
            paper-toast[type="warning"] {
                border-top: 2px solid var(--color-warning);
            }

            /* Paper Dropdown Menu Styles */
            ns-paper-dropdown paper-item:hover {
                background: var(--color-interactive-5);
                cursor: pointer;
            }
            
            /* Paper Tooltip Styles */
            paper-tooltip {
              --paper-tooltip: {
                max-width: 320px;
                background-color: white;
                border: 1px solid var(--blackhole);
                font-size: 14px;
                color: var(--blackhole);
                box-shadow: 3px 3px 3px var(--gandalf);
              };
            }
            
            paper-radio-group .radioSubtitle{
                margin-left: 39px;
                color: var(--steel);
                margin-top: -10px;
            }

            @media (min-width: 768px) {
                h1 {
                    @apply --title-large-desktop;
                }
                h2 {
                    @apply --title-medium;
                }
                .rhythm-y-20-adaptive > * {
                    margin-bottom: 0;
                }
            }
            
            @media (max-width: 1023px) {
                a.base.mobile-lock::before,
                .a-base.mobile-lock::before{
                    opacity: 1;
                    visibility: visible;
                }
            }
            
            @media (min-width: 1024px) {
                .responsive-container {
                    max-width: 1024px;
                }
            }
            
            @media (min-width: 1440px) {
                .responsive-container {
                    max-width: 1440px;
                }
            }

        </style>
    </template>
</dom-module>
`;

document.head.appendChild($_documentContainer);
