import {PolymerElement} from '@polymer/polymer/polymer-element.js';
import {html} from '@polymer/polymer/lib/utils/html-tag.js';

const $_documentContainer = document.createElement('div');
$_documentContainer.setAttribute('id', 'customGlobalStyles');
$_documentContainer.setAttribute('style', 'display: none;');

$_documentContainer.innerHTML = `
<custom-style>
  <style is="custom-style" include="common-transition-styles">

    html {
      /*////////////////////////////////
      // Polymer Mixins
      ////////////////////////////////*/

      /* ========== Typography ========== */
      --title-large-desktop: {
        font-family:    var(--font-family-primary);
        font-weight:    var(--font-weight-thin);
        font-size:      40px;
        line-height:    43px;
        text-transform: none;
      };
      --title-large-mobile: {
        font-family:    var(--font-family-primary);
        font-weight:    var(--font-weight-thin);
        font-size:      22px;
        line-height:    23px;
        text-transform: none;
      };
      --title-decor-desktop: {
        font-family:    var(--font-family-secondary);
        font-weight:    var(--font-weight-regular);
        font-size:      80px;
        line-height:    85px;
        padding-top:    15px;
        padding-bottom: 30px;
        text-transform: none;
      };
      --title-decor-tablet: {
        font-family:    var(--font-family-secondary);
        font-weight:    var(--font-weight-regular);
        font-size:      64px;
        line-height:    66px;
        padding-top:    15px;
        padding-bottom: 30px;
        text-transform: none;
      };
      --title-decor-mobile: {
        font-family:    var(--font-family-secondary);
        font-weight:    var(--font-weight-regular);
        font-size:      40px;
        line-height:    45px;
        text-transform: none;
      };
      --title-large-caps-desktop: {
        @apply --title-large-desktop;
        font-size:      38px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        line-height:    46px;
      };
      --title-large-caps-mobile: {
        @apply --title-large-mobile;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      };
      --title-medium: {
        font-family:    var(--font-family-primary);
        font-weight:    var(--font-weight-normal);
        font-size:      28px;
        line-height:    34px;
      };
      --title-thin-caps: {
        @apply --title-medium;
        font-weight:    var(--font-weight-thin);
        font-size:      20px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        line-height:    26px;
      };
      --title-bold-italic-caps: {
        @apply --title-thin-caps;
        font-style:     italic;
        font-weight:    var(--font-weight-bold);
      };
      --title: {
        font-family:    var(--font-family-primary);
        font-weight:    var(--font-weight-regular);
        font-size:      22px;
        line-height:    20px;
      };
      --title-bold: {
        @apply --title;
        font-weight:    var(--font-weight-semibold);
      };
      --title-thin: {
        @apply --title;
        font-weight:    var(--font-weight-thin);
      };
      --title-small: {
        font-family:    var(--font-family-primary);
        font-weight:    var(--font-weight-regular);
        font-size:      18px;
        line-height:    20px;
      };
      --title-small-bold: {
        @apply --title-small;
        font-weight:    var(--font-weight-semibold);
      };
      --subtitle: {
        font-family:    var(--font-family-primary);
        font-weight:    var(--font-weight-regular);
        font-size:      16px;
        line-height:    18px;
      };
      --subtitle-semibold: {
        @apply          --subtitle;
        font-weight:    var(--font-weight-semibold);
      };
      --subtitle-small: {
        @apply          --subtitle;
        font-size:      13px;
        line-height:    16px;
      };
      --subtitle-small-semibold: {
        @apply          --subtitle-small;
        font-weight:    var(--font-weight-semibold);

      };
      --subtitle-small-caps: {
        @apply          --subtitle-small;
        line-height:    13px;
        letter-spacing: 1px;
        text-transform: uppercase;
      };
      --subtitle-small-bold-caps: {
        @apply          --subtitle-small-caps;
        line-height:    18px;
        letter-spacing: 0;
        font-weight:    var(--font-weight-bold);
      };
      --subtitle-small-bold-italic-caps: {
        @apply          --subtitle-small-bold-caps;
        font-style:     italic;
      };
      --subtitle-xs-bold-italic-caps: {
        @apply          --subtitle-small-bold-italic-caps;
        font-size:      11px;
        line-height:    16px;
        letter-spacing: .2px;
      };
      --h5-desktop-bold-italic: {
        @apply          --subtitle;
        font-size:      20px;
        line-height:    20px;
        font-style:     italic;
        font-weight:    var(--font-weight-bold);
        text-transform: uppercase;
      };
      --h5-mobile-bold-italic: {
        @apply          --subtitle;
        font-size:      13px;
        line-height:    13px;
        font-style:     italic;
        font-weight:    var(--font-weight-bold);
        text-transform: uppercase;
      };
      --p-large: {
        font-family:    var(--font-family-primary);
        font-weight:    var(--font-weight-regular);
        font-size:      18px;
        line-height:    24px;
      };
      --p-large-semibold: {
        font-family:    var(--font-family-primary);
        font-weight:    var(--font-weight-semibold);
        font-size:      18px;
        line-height:    24px;
      };
      --p-medium: {
        font-family:    var(--font-family-primary);
        font-weight:    var(--font-weight-regular);
        font-size:      16px;
        line-height:    21px;
      };
      --p-small: {
        font-family:    var(--font-family-primary);
        font-weight:    var(--font-weight-regular);
        font-size:      14px;
        line-height:    18px;
      };

      --p-small-bold: {
        font-family:    var(--font-family-primary);
        font-weight:    var(--font-weight-bold);
        font-size:      13px;
        line-height:    21px;
      };

      /* ===== Links ===== */
      --a-base: {
        font-family:    var(--font-family-primary);
        font-weight:    var(--font-weight-semibold);
        font-size:      14px;
        letter-spacing: 0.6px;
        line-height:    17px;
      };
      --a-caps: {
        @apply --a-base;
        font-weight:    var(--font-weight-regular);
        font-size:      13px;
        text-transform: uppercase;
        line-height:    18px;
      };
      --a-bold-caps: {
        @apply --a-caps;
        font-weight:    var(--font-weight-semibold);
        letter-spacing: 0.8px;
      };
      --a-micro-caps: {
        @apply --a-base;
        text-decoration:none !important;
        font-weight:    var(--font-weight-semibold);
        font-size:      10px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        line-height:    14px;
      };
      --a-desktop-expressive: {
        @apply --a-base;
      };
      --a-mobile-expressive: {
        @apply --a-base;
        font-size:      21px;
      };
      --a-body-medium: {
        @apply --a-base;
        font-size:      16px;
        line-height:    20px;
        letter-spacing: normal;
        line-height:    20px;
      };
      --a-body-small: {
        @apply --a-base;
        font-weight:    var(--font-weight-bold);
        letter-spacing: normal;
      };
      --nav-label: {
        font-family:    var(--font-family-primary);
        font-weight:    var(--font-weight-regular);
        font-size:      15px;
        letter-spacing: 0.8px;
        line-height:    17px;
        text-transform: uppercase;
      };

      --font-button: {
        font-family:    var(--font-family-primary);
        font-weight:    var(--font-weight-semibold);
        font-size:      14px;
        line-height:    17px;
        letter-spacing: 1.07px;
        text-transform: uppercase;
        text-decoration: none !important;
      };

      /* ===== Separators ===== */

      --base-hr: {
        box-sizing: border-box;
        height: 2px;
        margin: 20px 0;
        border: none;
        display: inline-block;
        width: 100%;
        background-color: var(--gandalf);
      };

      --base-hr-thin: {
        box-sizing: border-box;
        height: 1px;
        margin: 15px 0;
        border: 0;
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid var(--gandalf);
      };

      /* Grid Breakpoints
        xs: 320;
        sm: 375;
        md: 768;
        lg: 1024;
        xl: 1366;
      */

      /* ===== Gradients ===== */
      --gradient-bifrost: {
        background: -webkit-gradient(linear, left top, right top, from(#AA10FF), color-stop(#3023AE), color-stop(#8743D8), color-stop(#53A0FD), to(#68CA87));
        background: -webkit-linear-gradient(left, #AA10FF, #3023AE, #8743D8, #53A0FD, #68CA87);
        background: -o-linear-gradient(left, #AA10FF, #3023AE, #8743D8, #53A0FD, #68CA87);
        background: linear-gradient(to right, #AA10FF, #3023AE, #8743D8, #53A0FD, #68CA87);
      };

      --hide: {
        opacity: 0;
        visibility: hidden;
        transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
      };

      --show: {
        opacity: 1;
        visibility: visible;
        transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
      };

      --aspect-ratio-1-1: 100%;
      --aspect-ratio-4-3: 75%;
      --aspect-ratio-16-9: 56.25%;
      --aspect-ratio-21-9: 42.857%;

      --app-drawer-width: 300px;

      /* ========== Paper Element Restyles ========== */
      --paper-radio-button-ink-size:0;
      --paper-radio-button-unchecked-color: var(--color-interactive-7);
      --paper-radio-button-checked-color: var(--color-interactive-7);
      --paper-checkbox-unchecked-color: var(--color-interactive-7);
      --paper-checkbox-checked-color: var(--color-interactive-7);
      --paper-checkbox-vertical-align: top;

      --paper-tabs-selection-bar-color: var(--color-interactive-7);
      --paper-tabs-selection-bar: {
          border-bottom-width: 3px;
          will-change: transform;
      };
      --paper-tabs-content: {
          text-transform: uppercase;
          color: var(--color-interactive-8);
          font-size: 15px;
          letter-spacing: 0.8px;
          line-height: 16px;
          font-weight: 400;
      };
      --paper-tab-content-unselected: {
          opacity: 1;
      };
      --paper-tab: {
          padding: 0;
      };

    }



  </style>
</custom-style>
`;

document.head.appendChild($_documentContainer);
