import {init} from '@nuskin/ns-util';
import {getConfiguration} from '@nuskin/configuration-sdk';

init({clientId: '735b1eb810304bba966af0891ab54053', clientSecret: '0deea37049e84157A406C0C01D29F300'});

const configMapsToLoad = ['Url', 'Basic', 'Shopping'];
getConfiguration(configMapsToLoad).then(() => console.log('webcomponents config loaded'));

// window.marked = require('marked');
import 'whatwg-fetch';
import lodash from 'lodash';
window._ = lodash;
import moment from 'moment';
window.moment = moment;
import '@nuskin/nuskinjquery';
import 'babel-polyfill';

// pull in vue and vuetify with vuetify styles
import Vue from 'vue';
window.Vue = Vue;
import Vuetify from 'vuetify';
import VueClipboard from 'vue-clipboard2';
Vue.use(Vuetify);
Vue.use(VueClipboard);
import 'vuetify/dist/vuetify.min.css';

import pack from '../package.json';
export var version = pack.version;
export * from './clientLibs.js';
import {ConfigService} from '@nuskin/ns-util';
export * from '@nuskin/ns-util';
export * from '@nuskin/ns-account';
export * from '@nuskin/ns-product';
export * from '@nuskin/ns-shop';
export * from '@nuskin/my-site-api';

import ExclusiveOfferSdk from '@nuskin/exclusive-offer-sdk';
export var QualificationService = ExclusiveOfferSdk;

export * from '@nuskin/ns-auth';
export * from '@nuskin/ns-app-elements';
export * from '@nuskin/ns-shop-elements';
export * from '@nuskin/ns-util-elements';
export * from '@nuskin/ns-form-elements';
export * from '@nuskin/ns-style-elements';

// Register Vue components
import * as appC from '@nuskin/app-components';
import * as productC from '@nuskin/product-components';
import * as designC from '@nuskin/design-components';

import * as loyaltyElements from '@nuskin/loyalty-elements';

const Components = {
    // app-components
    NsDonationForm: appC.NsDonationForm,
    NsDonationReceipt: appC.NsDonationReceipt,
    NsDynamicHtml: appC.NsDynamicHtml,
    NsFeature: appC.NsFeature,
    NsFooter: appC.NsFooter,
    NsFooterNav: appC.NsFooterNav,
    NsHeader: appC.NsHeader,
    NsHero: appC.NsHero,
    NsInformationButton: appC.NsInformationButton,
    NsInformationCardVue: appC.NsInformationCard,
    NsSearchbar: appC.NsSearchbar,
    NsSectionHeader: appC.NsSectionHeader,
    NsStripeDonationForm: appC.NsStripeDonationForm,
    NsTopnav: appC.NsTopnav,
    NsTopnavGivingBack: appC.NsTopnavGivingBack,
    NsTopnavImageOverlay: appC.NsTopnavImageOverlay,
    NsTopnavLinkList: appC.NsTopnavLinkList,
    NsTopnavNavigation: appC.NsTopnavNavigation,
    NsTopnavProductHighlight: appC.NsTopnavProductHighlight,
    NsTopnavProductLine: appC.NsTopnavProductLine,
    NsTopnavSection: appC.NsTopnavSection,
    // product-components
    NsProductCard: productC.NsProductCard,
    NsProductCarousel: productC.NsProductCarousel,
    NsProductDetail: productC.NsProductDetail,
    NsProductList: productC.NsProductList,
    NsProductListSortable: productC.NsProductListSortable,
    NsProductOffer: productC.NsProductOffer,
    // design-components
    NsExpressiveLinkVue: designC.NsExpressiveLink,
    // loyalty-elements
    NsLoyaltySignup: loyaltyElements.NsLoyaltySignup
};

Object.keys(Components).forEach((name) => {
    Vue.component(name, Components[name]);
});

export function loadCart() {
}

export function loadCheckout() {
}

export function loadLoyaltyElements() {
    return Promise.resolve()
}

export function loadMySite() {
    if (window.location.href.indexOf('playground') == -1) {
        setWebpackPublicPath();
    }
    return import(/* webpackChunkName: "my-site" */ '@nuskin/my-site');
}

export function loadMySiteHome() {
    if (window.location.href.indexOf('playground') == -1) {
        setWebpackPublicPath();
    }
    return import(/* webpackChunkName: "my-site-home" */ '@nuskin/my-site-home');
}

export function loadMySiteApi() {
    if (window.location.href.indexOf('playground') == -1) {
        setWebpackPublicPath();
    }
    return import(/* webpackChunkName: "my-site-api" */ '@nuskin/my-site-api');
}

export function loadSignup(codeBundlePath) {
    /*
     * NOTE that the if HTML page in the playground has the <base href='/'>, you
     * do not need to set __webpack_public_path__ to '/' because all the
     * webpack chunk bundles (e.g., signup.bundle.js) are already at the root level.
     *
     * However, if there is a <base href='/'> in the HTML page, there is a side
     * effect that all the <a href> will be defaulting to the root.  That means
     * if you have this <a href='#abc'>, the user will see the link like as '/#abc'
     *
     * So, we do NOT want the <base href='/'>, but we want dynamically change
     * the webpack public path.  Basically, what we say is that if the client
     * browser URL has playground, we assume all the webpack chunks are at
     * the root level.
     *
     * Note that if someone provide a codeBundlePath, it will use it as the
     * __webpack_public_path__ (remove anything after '/')
     */
    console.log('codeBundlePath:', codeBundlePath);
    setWebpackPublicPath(codeBundlePath);

    // return import(/* webpackChunkName: "signup" */ '@nuskin/ns-signup');
}



function setWebpackPublicPath(codeBundlePath) {
    if (codeBundlePath) {
        __webpack_public_path__ = codeBundlePath.substr(0, codeBundlePath.lastIndexOf("\/") + 1);
    } else {
        let config = ConfigService.getMarketConfig();
        __webpack_public_path__ = config.development.codeBundle.substr(0, config.development.codeBundle.lastIndexOf("\/") + 1);
    }
}
