import {PolymerElement} from '@polymer/polymer/polymer-element.js';
import {html} from '@polymer/polymer/lib/utils/html-tag.js';

const $_documentContainer = document.createElement('div');
$_documentContainer.setAttribute('id', 'commonShopStyles');
$_documentContainer.setAttribute('style', 'display: none;');

$_documentContainer.innerHTML = `
<dom-module id="common-shop-styles">
    <template>
        <style>
          [hidden] {
            display: none;
          }

          iron-form {
            width: 100%;
            margin: 0;
          }
          ns-button[custom]{
            --button-custom: {
              font-size: 14px;
              color: #ffffff;
              background: var(--color-primary);
              border-color: var(--color-primary);
              padding: 13px 20px;
              z-index: 0;
            };

            --button-custom-hover: {
              background-color: var(--dusk);
              border-color: var(--dusk);
              color: #ffffff;
            };

            --button-custom-active: {
              padding: 13px 20px;
              border: 1px solid var(--dusk);
              color: #ffffff;
            };
          }

          ns-button[custom][theme='secondary']{
            --button-custom: {
              font-size: 14px;
              color: var(--color-primary);
              background: transparent;
              border-color: var(--color-primary);
              padding: 13px 20px;
            };

            --button-custom-hover: {
              color: var(--dusk);
              border-color: var(--dusk);
            };

            --button-custom-active: {
              color: var(--dusk);
              border: 1px solid var(--dusk);
              padding: 13px 20px;
            };
          }

          ns-button[custom][theme='transparent']{
            color: var(--color-primary);
            --button-custom: {
              font-size: 14px;
              background: transparent;
              border-color: var(--color-primary);
              padding: 13px 20px;
            };

            --button-custom-hover: {
              color: var(--dusk);
              border-color: transparent;
            };

            --button-custom-active: {
              color: var(--dusk);
              border: 1px solid transparent;
              padding: 13px 20px;
            };
          }

          ns-button[custom][disabled] {
            --button-custom-disabled: {
              color: #fff;
              background-color: var(--color-gray-1);
              border-color: var(--color-gray-1);
            };
          }

          ns-button[custom][disabled]:hover{
            --button-custom-disabled: {
              color: #fff;
              background-color: var(--color-gray-1) !important;
              border-color: var(--color-gray-1) !important;
            }
          }

          header-row-tag {
            display: flex;
            justify-content: space-between;
          }

          modal-close-button {
            background-repeat: no-repeat;
            background-position: center center;
            height: 1.5rem;
            width: 1.5rem;
            float: right;
            cursor: pointer;
            padding: 0;
          }

          ns-dropdown {
            --ns-dropdown-wrap: {
              transition: all .15s ease-in-out;
              border: var(--global-border);
              min-height: 45px;
            };

            --ns-dropdown-list: {
              border-width: 0 1px 1px 1px;
              border-color: var(--color-gray-1);
              border-style: solid;
              z-index: 9999;
            };

            --ns-dropdown-wrap-focus: {
              border-width: 1px;
              border-style: solid;
              border-color: var(--color-primary);
            };

            --ns-dropdown-current-selection: {
              min-height: 45px;
            };
          }

          ns-input {
            --input-style: {
              border: var(--global-border);
            }
          }

          ns-number-increment {
            height: 33px;

            --increment-button: {
              width: 40px;
              background: #fafafa;
              color: #737374;
              font-weight: 200;
              font-size: 20px;
              box-sizing: border-box;
            }

            --increment-button-hover: {
              border-color: #e6e6e7;
              background-color: #f8f8f8;
              color: #00b2e3;
              transition: .2s ease-in-out;
            }

            --increment-input: {
              height: 33px;
              outline: none;
              border-width: 1px 0;
              border-type: solid;
              border-color: #e1e1e1;
              border-radius: 3px;
              box-sizing: border-box;
            }
          }

          ns-slotted-dropdown {
            --ns-dropdown-current-selection: {
              min-height: 45px;
            }
          }

          paper-dialog.modal {
            padding: 24px;
          }

          .padding-right {
            padding-right: 15px;
          }


          promo-code-tag ns-button[custom], shipping-code-tag ns-button[custom]{
            --button-custom: {
              color: #ffffff;
              font-size: 14px;
              background: var(--color-primary);
              border-color: var(--color-primary);
              padding: 10px 20px;
              z-index: 0;
            };

            --button-custom-hover: {
              color: #ffffff;
              background-color: var(--dusk);
              border-color: var(--dusk);
            };

            --button-custom-active: {
              color: #ffffff;
              padding: 10px 20px;
              border: 1px solid var(--dusk);
            };
          }

          section-wrapper-tag {
            display: block;
            margin: 0 0 1em 0;
            @apply --standard-text-style;
          }

          @media print {
            .no-print {
              display: none;
            }

            column-layout {
              grid-template-columns: 1fr;
            }

            .column-left {
              order: 2;
            }

            .column-right {
              order: 1;
            }

            .adp-manage-page-title,
            .selected-adr-container {
              display: inline-block;
            }
          }

          @media (max-width: 812px) {
            ns-button[custom] {
              --button-custom: {
                font-size: 12px;
                color: #fff;
                background: var(--color-primary);
                border-color: var(--color-primary);
                padding: 10px 20px;
              };
  
              --button-custom-active: {
                padding: 10px 20px;
              };
            }

            ns-button[custom][theme='secondary'] {
              --button-custom: {
                font-size: 12px;
                color: var(--color-primary);
                background: var(--color-secondary);
                border-color: var(--color-secondary);
                padding: 10px 20px;
              };
  
              --button-custom-active: {
                padding: 10px 20px;
              };
            }
          }

          @media (max-width: 767px) {
            modal-body {
              margin-left: 0;
              overflow-y: scroll;
              max-height: 26rem;
            }

            modal-body ul {
              padding: 0;
            }

            modal-body ul li {
              padding: 0 0 10px 0;
            }

            header-row-tag {
              display: block;
              justify-content: left;
            }
          }

          @media (max-width: 375px) {
            header-row-tag > ns-button {
              width: 100%;
              margin: 5px 0 0;
            }

            ns-dropdown {
              --ns-dropdown-current-selection: {
                line-height: 18px;
              };

              --ns-dropdown-current-selection-content: {
                max-width: 20em;
              };
            }
          }

          html {
            --standard-text-style: {
              font-size: 0.825em;
              line-height: 1em;
            };
          }

        </style>
    </template>
</dom-module>
`;

document.head.appendChild($_documentContainer);
