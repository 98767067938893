import {events, ConfigService, storage, util} from '@nuskin/ns-util';
import {AccountManager, newAccount, account, UserService} from '@nuskin/ns-account';
import {gigyaService} from '@nuskin/ns-auth';

$.subscribe(
    events.authentication.INVALID_NEW_LOGIN_PAGE,
    function (object) {
        var isStatusDefined = (object && object.hasOwnProperty("responseJSON") && object.responseJSON && object.responseJSON.hasOwnProperty("Status"));
        if (isStatusDefined && object.responseJSON.Status.toLowerCase() === "terminated") {
            $.publish(events.authentication.ERROR_ACCOUNT_TERMINATED);
        } else if (object && object.error && object.error === 'User does not have a sponsor.') {
            $.publish(events.authentication.ERROR_SPONSOR_NOT_FOUND);
        } else {
            var username = $('#defaultLogInForm-username').val(),
                date = new Date(),
                minutes = 30;
            date.setTime(date.getTime() + (minutes * 60 * 1000));
            var userLoginFailedCounter = $.cookie('userLoginFailed-' + username);
            if (userLoginFailedCounter === null) {
                $.cookie('userLoginFailed-' + username, 1, {path: '/', expires: date});
            }
            else {
                userLoginFailedCounter++;
                $.cookie('userLoginFailed-' + username, userLoginFailedCounter, {path: '/', expires: date});
            }
            if (userLoginFailedCounter >= 7) {
                $.publish(events.authentication.ERROR_LOGOUT_MESSAGE);
            }
            else if (userLoginFailedCounter >= 3) {
                $.publish(events.authentication.ERROR_TOO_MANY_LOGIN_ATTEMPTS);
            }
            else {
                $.publish(events.authentication.ERROR_LOGIN_FAILED); // shows generic error message
            }
        }
    }
);

$.subscribe(
    events.authentication.INVALID,
    function (object) {
        window.showLoginPopup();
        var isStatusDefined = (object && object.hasOwnProperty("responseJSON") && object.responseJSON && object.responseJSON.hasOwnProperty("Status"));
        if (isStatusDefined && object.responseJSON.Status.toLowerCase() === "terminated") {
            $("#user-account-terminated").show();
        } else if (object && object.error && object.error && object.error === 'User does not have a sponsor.') {
            $('#defaultLogInForm-noSponsorError').css('display', 'block'); // shows specific error message
        } else {
            var username = $('#defaultLogInForm-username').val(),
                date = new Date(),
                minutes = 30;
            date.setTime(date.getTime() + (minutes * 60 * 1000));
            var userLoginFailedCounter = $.cookie('userLoginFailed-' + username);
            if (userLoginFailedCounter === null) {
                $.cookie('userLoginFailed-' + username, 1, {path: '/', expires: date});
            }
            else {
                userLoginFailedCounter++;
                $.cookie('userLoginFailed-' + username, userLoginFailedCounter, {path: '/', expires: date});
            }
            if (userLoginFailedCounter >= 7) {
                $('#defaultLogInForm-lockoutErrors').css('display', 'block');
            }
            else if (userLoginFailedCounter >= 3) {
                $('#defaultLogInForm-tooManyLoginAttemptsErrors').css('display', 'block');
            }
            else {
                $('#defaultLogInForm-logInFormErrors').css('display', 'block'); // shows generic error message
            }

        }
    }
);

$.subscribe(
    events.authentication.STATUS,
    function (object) {
        window.showLoginPopup();
        var isStatusDefined = (typeof object !== "undefined" && object.hasOwnProperty("Status"));
        if (isStatusDefined && object.Status.toLowerCase() === "terminated") {
            $("#user-account-terminated").show();
        } else if (object && object.error && object.error && object.error === 'User does not have a sponsor.') {
            $('#defaultLogInForm-noSponsorError').css('display', 'block'); // shows specific error message
        } else {
            $('#defaultLogInForm-logInFormErrors').css('display', 'block'); // shows generic error message
        }
    }
);

$.subscribe(
    events.authentication.LOGOUT_LEGACY,
    function (object) {
        $(".loggedIn").hide();
        $(".loggedIn-10").hide();
        $(".loggedIn-20").hide();
        $(".loggedIn-30").hide();
        $(".loggedIn-wholesale").hide();
        $(".loggedIn-retail").hide();
        $(".notLoggedIn").show();
        if (typeof ribbonWelcome !== "undefined" && typeof enableCPMRibbon !== "undefined" && !enableCPMRibbon) {
            $("#firstName").removeClass("ribbonOpen").show();
        }
    }
);

/*
 $.subscribe(nuskin.events.authentication.AGELOCME_LOGIN, function () {
 $(".login-close").click();
 });
 */

$.subscribe(
    events.authentication.VALID,
    function (object) {
        var $firstName = $("#firstName");
        $('.notLoggedIn').css('display', 'none');
        var loginString = "";
        if (typeof ribbonWelcome !== "undefined" && typeof enableCPMRibbon !== "undefined" && !enableCPMRibbon) {
            $firstName.addClass('ribbonOpen');
            loginString += ribbonWelcome + " ";
        }
        if (object.displayName && object.displayName !== null) {
            loginString += object.displayName;
        } else if (object.localName && object.localName !== null) {
            loginString += object.localName;
        } else {
            loginString += object.firstName;
        }
        $firstName.html(loginString);
        $('#logInInfo .loggedIn').css('display', 'block');

        // Show Responsive header logout button.
        if (typeof RightSideNav === 'object' && $('#rightNavSideBar .logout').length === 0) {
            var logoutElement = '<a class="logout text-btn" href="#">' + $('#logInInfo .loggedIn').text() + '</a>';
            RightSideNav.setLogOutString(logoutElement);

            // Add the logout button listener.
            $('a.logout').click(function (e) {
                AccountManager.logout();
                e.preventDefault();
            });
        }

        $('.loggedIn-10').css('display', 'none');
        $('.loggedIn-20').css('display', 'none');
        $('.loggedIn-30').css('display', 'none');
        $('.loggedIn-wholesale').css('display', 'none');
        $('.loggedIn-retail').css('display', 'none');
        $('.loggedIn-' + object.accountType).css('display', 'block');
        var priceType = (object.priceType === 'WHL' || object.priceType === 'PRF') ? 'wholesale' : 'retail';

        if (document.URL.indexOf("zh_TW") !== -1 && !ConfigService.getMarketConfig().taiwan.enableGlobalAuth) {
            priceType = (object.accountType === "10" || object.accountType === "30") ? "wholesale" : "retail";
        }
        $('.loggedIn-' + priceType).css('display', 'block');

        // addition for product details
        if (productDetails) {
            $(".loginToView").css('display', 'none');
            for (var propName in productDetails) {
                var pDetailDiv = document.getElementById(propName);
                pDetailDiv.innerHTML = unescape(productDetails[propName]);
            }
        }

        //code to add hello specific classes for formatting the myoffice dropdown
        var lastIndex = -1;
        var nodeList = $('ul.myOffice li ul li').each(function (index) {
            $(this).removeClass('first');
            $(this).removeClass('last');
            if ('block' === $(this).css('display')) {
                if (lastIndex === -1) {
                    $(this).addClass('first');
                }
                lastIndex = index;
            }
        });
        if (lastIndex > -1) {
            $(nodeList[lastIndex]).addClass('last');
        }

        //init taiwan cart button when we login
        if (typeof TaiwanCartButton !== "undefined" && document.URL.indexOf("zh_TW") !== -1 && ConfigService.getMarketConfig().taiwan.enableEnhancedMiniCart) {
            TaiwanCartButton.initCartObject();
        }
    }
);

$.subscribe(
    events.account.VOLUME_ADDED,
    function (object) {
        if (object.accountType !== '20') {
            $('#volumes-id').text(object.id);
            var volumesPanel = $('#volumesPanel');
            if (object.volumes !== undefined) {
                var volumesObj = '';
                for (var key in object.volumes) {
                    if (typeof volumesWindowURL === "undefined") {
                        volumesObj = volumesObj + '<a href="" target="_blank" class="requiresAuthentication"><strong>' + key + ': </strong><label>' + object.volumes[key] + ' &nbsp;</label></a>';
                    } else {
                        volumesObj = volumesObj + '<a href="' + volumesWindowURL + '" target="_blank" class="requiresAuthentication"><strong>' + key + ': </strong><label>' + object.volumes[key] + ' &nbsp;</label></a>';
                    }
                }
                volumesPanel.append(volumesObj);
            } else {
                if (volumesPanel !== undefined) {
                    volumesPanel.text('');
                }
            }
        }
    }
);

$.subscribe(
    events.authentication.INVALID_SHOPPING_COUNTRY,
    function (object) {
        $("#invalid-shopping-country-error").show();
    }
);

$.subscribe(
    events.account.INIT_COMPLETE,
    function checkUserTimedOut() {
        var loginTime = storage.getItem(storage.keys.LOGIN_TIMED_OUT);
        if (loginTime) {
            var EXPIRED_ERROR_TIME = 7200000; // 2 hours
            var timeDif = new Date().getTime() - loginTime;
            if (timeDif > EXPIRED_ERROR_TIME) {
                storage.removeItem(storage.keys.LOGIN_TIMED_OUT);
                window.showLoginPopup();
                $("#user-timed-out-error").show();
            }
        }
    }
);

window._nuskinPopupLogin = false;
$.subscribe(events.authentication.LOGIN_REQUESTED, function () {
    window._nuskinPopupLogin = true;
});

//***********************************************************************
// Originally from loginForm.js
window.resetLoginForm = function () {
    $(".logInFormErrors").hide();
    $(".logInFormPanel").hide();
    $("#logInFormMain").show();
};

window.resetPasswordCall = function (userName, successCallback, errorCallback) {
    var passwordResetService = "/account-util/api/v1/person/" + escape(userName) + "/passwordChangeEmail";
    $.ajax({
        type: "GET",
        url: passwordResetService,
        success: successCallback,
        error: errorCallback,
        beforeSend: function (xhr) {
            xhr.setRequestHeader("client_id", ConfigService.getMarketConfig().checkout.clientId);
            xhr.setRequestHeader("client_secret", ConfigService.getMarketConfig().checkout.clientSecret);
        }
    });
};

window.passwordReminder = function (userName, successCallback, errorCallback) {
    var passwordReminderService = util.getValue("nuskin.configuration.passwordReminderService", "/signupApp/service/reminder/password/");

    $.ajax({
        type: "GET",
        url: passwordReminderService + util.countryCode + "/" + util.languageCode + "/" + escape(userName),
        success: successCallback,
        error: errorCallback
    });
};

window.showHeaderLoginPage = function(params) {
    // Show the responsive header login page.
    if (params && !params.keepAfter) {
        AccountManager.setAfterLoginParams(params);
        AccountManager.setAfterLoginUrl(params.href, ((params.target && params.target === '_blank')
            || (params.rel && params.rel === 'external')));
    } else {
        AccountManager.setAfterLoginParams(null);
        AccountManager.setAfterLoginUrl(null);
    }

}

$(function () {
    var USER_NAME_COOKIE = 'username';
    var storedUsername = $.cookie(USER_NAME_COOKIE);

    $("#forgotUsernameLink").click(function () {
        $(".logInFormErrors").hide();
        $(".logInFormPanel").hide();
        $("#forgotUsernameForm").show();
        $("#forgotPasswordForm-id").focus();
    });

    $("#forgotPasswordLink").click(function () {
        $(".logInFormErrors").hide();
        var forgotPasswordFormUsernameText = $("#defaultLogInForm-username").val();
        if (forgotPasswordFormUsernameText) {
            var passwordResetSuccess = function (response) {
                $("#password-email-success").hide();
                $("#password-email-error").hide();
                $(".logInFormPanel").hide();
                $("#thankYouForm-password").show();
                if (response.hasOwnProperty("String") && response.String === "SUCCESS") {
                    var messageObject = $("#password-email-text");
                    var messageString = $("#username-email-originaltext").val();
                    var EMAIL_TAG = "<%tag%>";
                    if (messageString.indexOf(EMAIL_TAG) !== -1) {
                        var emailAddress = response['email'];
                        messageString = messageString.replace(EMAIL_TAG, maskEmail(emailAddress));
                        messageObject.text(messageString);
                    }
                    $("#password-email-success").show();
                    $(".thankYouForm-signInButton").focus();
                } else {
                    $("#password-email-error").show();
                }
            };

            var passwordResetError = function () {
                $("#logInFormMain").hide();
                $("#forgotPasswordEnterID").show();
                $("#forgotPasswordEnterId-Error").show();
                $("#username-id").val("");
            };

            resetPasswordCall(forgotPasswordFormUsernameText, passwordResetSuccess, passwordResetError);
        } else {
            let taiwanLink = nuskin.configuration.taiwan.forgotPasswordLink;
            if (nuskin.configuration.markets === "TW" && taiwanLink) {
                window.location = taiwanLink;
            } else {
                $(".logInFormPanel").hide();
                $("#forgotPasswordEnterID").show();
                $("#username-id").val("");
                $("#username-id").focus();
            }
        }
    });

    $("#passwordusernameidbutton").click(function () {
        $(".logInFormErrors").hide();
        var forgotPasswordFormUsernameText = $("#username-id").val();
        if (forgotPasswordFormUsernameText) {
            var passwordResetSuccess = function (response) {
                $("#password-email-success").hide();
                $("#password-email-error").hide();
                $(".logInFormPanel").hide();
                $("#thankYouForm-password").show();
                if (response.hasOwnProperty("String") && response.String === "SUCCESS") {
                    $("#password-email-success").show();
                    var messageObject = $("#password-email-text");
                    var messageString = $("#username-email-originaltext").val();
                    var EMAIL_TAG = "<%tag%>";
                    if (messageString.indexOf(EMAIL_TAG) !== -1) {
                        var emailAddress = response['email'];
                        messageString = messageString.replace(EMAIL_TAG, maskEmail(emailAddress));
                        messageObject.text(messageString);
                    }

                    $(".thankYouForm-signInButton").focus();
                } else {
                    $("#password-email-error").show();
                }
            };

            var passwordResetError = function () {
                $("#forgotPasswordEnterId-Error").show();
                $("#logInFormMain").hide();
                $("#forgotPasswordEnterID").show();
                $("#username-id").val();
            };

            resetPasswordCall(forgotPasswordFormUsernameText, passwordResetSuccess, passwordResetError);
        } else {
            $("#forgotPasswordEnterId-Error").show();
        }
    });

    $("#backButton").click(function () {
        $("#forgotPasswordForm").hide();
        $("#forgotPasswordEnterID").hide();
        $(".logInFormErrors").hide();
        $("#logInFormMain").show();
    });

    $("#username-id").keypress(function (event) {
        if (event.keyCode == "13") {
            $("#passwordusernameidbutton").click();
        }
    });

    $("#forgotUsernameForm-idButton").click(function () {
        $(".logInFormErrors").hide();
        var forgotPasswordFormIdElement = $("#forgotPasswordForm-id");
        if (forgotPasswordFormIdElement) {
            $(".emailSpinner").show();
            $(this).prop("disabled", true);

            var successCallback = function (response, ioArgs) {
                $("#username-email-success").hide();
                $("#username-email-error").hide();
                $(".emailSpinner").hide();
                $("#forgotUsernameForm-idButton").prop("disabled", false);
                $(".logInFormPanel").hide();
                if (response.indexOf('Email Sent') === 0) {
                    var messageObject = $("#username-email-text");
                    var messageString = $("#username-email-originaltext").val();
                    var EMAIL_TAG = "<%tag%>";
                    if (messageString.indexOf(EMAIL_TAG) !== -1) {
                        var emailAddress = response.substring("Email Sent to ".length, response.length);
                        messageString = messageString.replace(EMAIL_TAG, maskEmail(emailAddress));
                        messageObject.text(messageString);
                    }
                    $("#thankYouForm-username").show();
                    $("#username-email-success").show();
                    $(".thankYouForm-signInButton").focus();
                } else {
                    $("#thankYouForm-noemail").show();
                }
            };

            var errorCallback = function () {
                $(".emailSpinner").hide();
                $("#forgotUsernameForm-idButton").prop("disabled", false);
                $("#forgotPasswordServerErrors-id").show();
            };

            passwordReminder(forgotPasswordFormIdElement.val(), successCallback, errorCallback);
        }
    });

    $("#forgotPasswordForm-id").keypress(function (event) {
        if (event.keyCode == "13") {
            $("#forgotUsernameForm-idButton").click();
        }
    });

    $("#hintForm-signInButton").click(window.resetLoginForm);

    $("#hintForm-idButton").click(function () {
        $(".logInFormErrors").hide();
        var forgotPasswordFormUsernameText = $("#username-id").val();
        if (!forgotPasswordFormUsernameText) {
            forgotPasswordFormUsernameText = $("#defaultLogInForm-username").val();
        }
        if (forgotPasswordFormUsernameText) {
            $(".emailSpinner").show();
            $(this).prop("disabled", true);

            var successCallback = function (response, ioArgs) {
                console.log("success callback ");
                $("#password-email-success").hide();
                $("#password-email-error").hide();
                $("#hintForm-idButton").prop("disabled", false);
                $(".emailSpinner").hide();
                $(".logInFormPanel").hide();
                if (response.hasOwnProperty("String") && response.String === "SUCCESS") {
                    var messageObject = $("#password-email-text");
                    var messageString = $("#password-email-originaltext").val();
                    var EMAIL_TAG = "<%tag%>";
                    if (messageString.indexOf(EMAIL_TAG) !== -1) {
                        console.log("response:" + response);
                        console.log("response email:" + response["email"]);
                        messageString = messageString.replace(EMAIL_TAG, maskEmail(response["email"]));
                        messageObject.text(messageString);
                    }
                    $("#thankYouForm-password").show();
                    $("#password-email-success").show();
                    $(".thankYouForm-signInButton").focus();
                } else {
                    $("#thankYouForm-noemail").show();
                    $("#noemail-message").show();
                }
            };

            var errorCallback = function (response, ioArgs) {
                $(".emailSpinner").hide();
                $("#hintForm-idButton").prop("disabled", false);
                if (response.hasOwnProperty("responseText") && response.responseText.indexOf("{") !== -1) {
                    var responseObject = JSON.parse(response.responseText);
                    if (loginFormPanelConfig && loginFormPanelConfig.hasOwnProperty("errorCodeMapping") && loginFormPanelConfig.errorCodeMapping.hasOwnProperty(responseObject.code)) {
                        $("#forgotPasswordServerErrors-password").text(loginFormPanelConfig.errorCodeMapping[responseObject.code]);
                    }
                }
                $("#forgotPasswordServerErrors-password").show();
                $("#forgotPasswordForm").show();
            };

            window.resetPasswordCall(forgotPasswordFormUsernameText, successCallback, errorCallback);
        }
    });

    function maskEmail(email) {
        var newEmail = email;
        if (email.indexOf("@") !== -1) {
            newEmail = email.substring(0, 2) + "********" + email.substring(email.indexOf("@"), email.length);
        }
        return newEmail;
    }

    $(".thankYouForm-signInButton").click(window.resetLoginForm).keypress(function (event) {
        if (event.keyCode === "13") {
            window.resetLoginForm();
        }
    });

    var signInHandler = function (formId) {
        //only allow signin if no logged in user
        toggleLoginDisable();
        if (typeof useSapIdLogin !== 'undefined' && useSapIdLogin) {
            var sapId = $('#' + formId + '-sapId').val();
            var email = $('#' + formId + '-email').val();
            $('#' + formId + '-logInFormErrors').css('display', 'none');
            sapIdLogin.authenticate(sapId, email);
        } else {
            if (!AccountManager.currentData) {
                var username = $('#' + formId + '-username').val();
                var password = $('#' + formId + '-password').val();
                $('#' + formId + '-logInFormErrors').css('display', 'none');
                AccountManager.authenticate(username, password);
            }
        }
    };

    // Log In Form Pop Up
    $('a.pop').click(function (e) {
        $.publish(events.authentication.REQUEST_FOR_LOGIN);
        // window.showLoginPopup();
        e.preventDefault();
    });
    $('a.logout').click(function (e) {
        if (nuskin.configuration.useGigyaLogin) {
            aem.gigyaService.logout();
            AccountManager.logout();
        } else {
            AccountManager.logout();
        }
        e.preventDefault();
    });
    $('button.signIn').click(function (e) {
        signInHandler($(this).attr('id'));
        e.preventDefault();
    });
    $('input.logInForm-password').keypress(function (e) {
        if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
            signInHandler($(this).attr('id').replace('-password', ''));
            e.preventDefault();
        }
    });

    $('input#sapIdLogInForm-email').keypress(function (e) {
        if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
            signInHandler("sapIdLogInForm");
            e.preventDefault();
        }
    });

    if (window.location.hash === "#showLogin") {
        window.showLoginPopup();
    }

    if (window.location.hash === "#showLoginKeepAfter") {
        window.showLoginPopup({keepAfter: true});
    }

    if (window.location.hash === "#showLandingLogin") {
        window.showLoginPopup();
        $('#campaignsText').show();
    }

    if (typeof(showLoginError) !== 'undefined' && showLoginError) {
        $.publish(events.authentication.INVALID);
    }

    if (window._nuskinPopupLogin) {
        window.showLoginPopup();
    }

    $('a.authenticate').click(function (e) {
        if (!AccountManager.currentData) {
            window.showLoginPopup(this);
            e.preventDefault();
        }
    });

    $('#rememberMe').click(function () {
        setRememberMeState();
    });

    $('#defaultLogInForm').click(function () {
        setRememberMeState();
    });

    function setRememberMeState() {
        // If checked, store username in the username cookie
        if ($('#rememberMe').is(':checked')) {
            var username = $('#defaultLogInForm-username').val();
            $.cookie(USER_NAME_COOKIE, username, {path: '/', expires: 9999});
        }

        // Else clear the username cookie
        else {
            $.cookie(USER_NAME_COOKIE, '', {path: '/', expires: -1});
        }
    }

    if (storedUsername && storedUsername.length > 0) {
        $('#defaultLogInForm-username').val(storedUsername);
        $('#rememberMe').prop("checked", true);
    }
});

//***********************************************************************

//***********************************************************************
// The following is extracted from global.js
//stuff added for nuskin js library integration
window.logInPopup = null;

/**
 * NON CAS Implementation of displaying the login screen
 */
window.showLoginPopup = function (params) {
    $('#campaignsText').hide();
    if (nuskin.util.countryCode === 'TW') {
        showHeaderLoginPage(params);
        // Responsive mode:
        if (ResponsiveHeader && ResponsiveHeader.isResponsiveMode()) {
            ResponsiveHeader.showRightSideNavWithLoginMsg();
        }
        // Non-responsive (i.e. desktop/laptop) mode:
        else {
            if (typeof useSapIdLogin !== 'undefined' && useSapIdLogin) {
                window.showSapIdLogin(params);
            } else {
                window.showNormalLogin(params);
            }
        }
    }
    // All other countries navigate to the Gigya login (TW doesn't work on Gigya):
    else {
        window.goToGigyaLoginPage(params);
    }
};

window.goToGigyaLoginPage = function (params) {
    showHeaderLoginPage(params);
    var baseLoginUrl = '';
    var destination = window.location.href.replace(/&/g, "%26");
    if (destination.indexOf("#showLogin") > -1) {
        destination = destination.replace("#showLogin", "");
    }
    destination = nuskin.util.Url.encodeParameter(window.location.href);
    if(nuskin.configuration.useNewSignin) {
        baseLoginUrl = aem.gigyaService.getNewLoginUrl(nuskin.util.languageCode);
    } else {
        if (nuskin.configuration.isMicrosite) {
            baseLoginUrl = nuskin.configuration.gigyaLoginUrl + nuskin.util.languageCode + '.html';
        } else {
            baseLoginUrl = aem.gigyaService.getLoginUrl(nuskin.util.languageCode);
        }
    }
    var loginUrlParams = baseLoginUrl + '?destination={destination}&cancel={cancel}';
    var loginUrl = loginUrlParams.replace('{destination}', destination).replace('{cancel}', destination);
    try {
        if (nuskin.util.countryCode.length > 0) {
            loginUrl = loginUrl + '&market=' + nuskin.util.countryCode;
        }
    } catch (e) {
        console.warn('marketLoginPanel caught exception -> ' + e.message);
    }
    if (nuskin.configuration.isMicrosite) {
        loginUrl = loginUrl + '&isMicrosite=true';
    }
    if (util.getLocaleUrl()) {
        loginUrl += "&localepage=" + nuskin.util.localePage;
    }
    window.location = loginUrl;
};

window.toggleLoginDisable = function () {
    var loginButton = $("#defaultLogInForm");
    var isDisabled = loginButton.attr("disabled");
    if (typeof isDisabled !== "undefined" && isDisabled !== false) {
        loginButton.prop("disabled", false);
    } else {
        loginButton.prop("disabled", true);
    }
};

window.showForcedLoginPopup = function (error, params) {
    //need to wait for document to finish loading
    $(function () {
        showHeaderLoginPage(params);
        window.resetLoginForm();
        $('#logInFormErrors').css('display', 'none');
        $('#username').val('');
        $('#password').val('');
        $('#logInForm').css('display', 'block');

        $(".btn.close").click(function (e) {
            e.preventDefault();
            window.history.back();
        });

        window.logInPopup = $('#domWindow');
        var modalDiv = $("<div id='modal'></div>").css("width", "100%").css("height", "100%").css("opacity", ".3")
            .css("background-color", "#000").css("z-index", "149").css("position", "fixed");
        $("body").prepend(modalDiv);

        if (error) {
            $("#defaultLogInForm-logInFormErrors").text(error).show();
        }

        window.logInPopup.show();

        // For IE7 display problem DAY-57 (the user click 'forgot passowrd', then close, and login again)
        // Somehow, we need to show the popup first, then do a block and none to clear the popup
        if (BrowserDetection.isIE() && BrowserDetection.browserVersion <= 7) {
            $('#forgotPasswordForm').css('display', 'block');
            $('#forgotPasswordForm').css('display', 'none');
        }
        $('.logInForm-username').focus();
    });
};

/**
 * CAS Implementation of displaying the login screen
 */
window.showCASLogin = function (params) {
    var newWindow = false;
    if (params && (params.target && params.target === '_blank') || (params.rel && params.rel === 'external')) {
        newWindow = true;
    }

    if (params) {
        AccountManager.setAfterLoginParams(params);
        AccountManager.setAfterLoginUrl(params.href, newWindow);
    } else {
        AccountManager.setAfterLoginParams(null);
        AccountManager.setAfterLoginUrl(null);
    }

    var loginUrl = util.authenticationHost + "/index.jsp";

    (newWindow) ? window.open(loginUrl, '', '') : window.location = loginUrl;
};

window.showSapIdLogin = function (params) {
    showHeaderLoginPage(params);


    $('.logInFormErrors').css('display', 'none');
    $('.logInFormPanel').css('display', 'none');
    $('#sapIdLogInFormMain').css('display', 'block');
    $('#sapId').val('');
    $('#email').val('');

    window.logInPopup = $('#domWindow').jqm({
        closeClass: 'close'
    });
    window.logInPopup.jqmShow();

    $('#sapId').focus();
};

window.showNormalLogin = function (params) {
    showHeaderLoginPage(params);

    window.resetLoginForm();
    $('#logInFormErrors').css('display', 'none');
    $('#invalid-shopping-country-error').css('display', 'none');
    $('#username').val('');
    $('#password').val('');
    $('#logInForm').css('display', 'block');

    // For some reason the login dialog is not always on the page
    // So we need to make sure it exists here. See global/.../responsiveBasic/.../header.js::$(window).resize
    if ($('.normallogin.marketSite #logInForm').length === 0) {
        // Put the login dialog back
        $('.normallogin.marketSite').prepend($('#logInForm'));
    }

    // If the screen is smaller than 767 we assume mobile device and we don't show the overlay.
    if ($(window).width() <= 767) {
        window.logInPopup = $('#domWindow').jqm({
            overlay: 0,
            closeClass: 'close'
        });
    }
    else {
        window.logInPopup = $('#domWindow').jqm({
            closeClass: 'close'
        });
    }
    window.logInPopup.jqmShow();

    // For IE7 display problem DAY-57 (the user click 'forgot passowrd', then close, and login again)
    // Somehow, we need to show the popup first, then do a block and none to clear the popup
    $('#forgotPasswordForm').css('display', 'block');
    $('#forgotPasswordForm').css('display', 'none');
    $('#username').focus();
};

window.sapIdLogin = (function () {
    var sapIdLoginCookieName = "sapIdLogin.account.data";

    var updateSchemeWithCurrent = function (aUrl) {
        var rv = aUrl;
        if (aUrl.indexOf(window.location.protocol) <= -1) {
            // that means either the protocol(scheme) is different or there is no protocol at all

            var loc = aUrl.indexOf(":");
            if (loc > 0) {
                // that means it has a protocol in the url
                rv = window.location.protocol + aUrl.substring(loc + 1);
            }
        }

        return rv;
    };

    var authenticate = function (sapId, email) {
        if (!sapId) {
            $.publish(events.authentication.INVALID_LOGIN);
            return;
        }

        // just a really simple email test  xxxx@xxxx.xx
        if (!email || !/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
            $.publish(events.authentication.INVALID_EMAIL);
            return;
        }

        window.sapIdLogin.sapId = sapId;
        window.sapIdLogin.email = email;
        var tempUrl = window.sapIdLogin.validateUrl + sapId;
        $.ajax({
            url: updateSchemeWithCurrent(tempUrl),
            headers: {
                'client_id': ConfigService.getMarketConfig().checkout.clientId,
                'client_secret': ConfigService.getMarketConfig().checkout.clientSecret
            },
            type: "GET",
            dataType: "json",
            crossDomain: true,
            cache: false,  // for IE to not do the caching...
            success: qualifyingCheck,
            error: window.sapIdLoginErrorHandler
        });

    };

    var qualifyingCheck = function (results) {
        var tempUrl = window.sapIdLogin.qualifyingUrl;
        if (results && results["sapId"]) {
            window.sapIdLogin.user = results;
            // Copy customerType to accountType
            if (!window.sapIdLogin.user.hasOwnProperty("accountType") && window.sapIdLogin.user.hasOwnProperty("customerType")) {
                // copy customerType to the accountType because the validate call return customerType instead of accountType
                window.sapIdLogin.user["accountType"] = window.sapIdLogin.user["customerType"];
            }
            // overwriting the email in the user from the newly entered email
            window.sapIdLogin.user["email"] = window.sapIdLogin.email;

            tempUrl += "?distributor=" + window.sapIdLogin.sapId;
            tempUrl += "&event=" + window.sapIdLogin.event;
            $.ajax({
                url: updateSchemeWithCurrent(tempUrl),
                type: "GET",
                dataType: "json",
                crossDomain: true,
                cache: false,  // for IE to not do the caching...
                success: returnHandler,
                error: sapIdLoginErrorHandler
            });
        } else {
            $.publish(events.authentication.INVALID_LOGIN);
        }
    };

    var returnHandler = function (results) {
        if (results && results["status"] && results["status"] === 'Y') {
            // Set login time.
            window.sapIdLogin.user["loginTime"] = new Date().getTime();

            newAccount.setUser(JSON.stringify(window.sapIdLogin.user));
            $.cookie(sapIdLoginCookieName, JSON.stringify(window.sapIdLogin.user), {path: '/'});

            // publish the authenticated message, such that it will update the welcome name on top
            $.publish(events.authentication.VALID, [window.sapIdLogin.user]);

            // In order for the shop stuff to work, we need to publish INITIALIZATION_COMPLETE
            AccountManager.currentData = window.sapIdLogin.user;
            //nuskin.account.currentData.eid = user.eid;
            account.initialized = true;
            $.setValue(events.account.INIT_COMPLETE);

            if (AccountManager.getAfterLoginUrl()) {
                window.location = AccountManager.getAfterLoginUrl().url;
            } else {
                // Hide the loginPopup
                window.logInPopup.jqmHide();
            }
        } else {
            $.publish(events.authentication.INVALID_LOGIN);
        }
    };

    var sapIdLoginErrorHandler = function (jqXHR, textStatus, errorThrown) {
        $.publish(events.authentication.INVALID_LOGIN);
    };

    var logout = function () {
        AccountManager.clearSessionVariables();
        // clear currentData
        if (!AccountManager.currentData) {
            AccountManager.currentData = null;
        }
        $.cookie(sapIdLoginCookieName, null, {path: '/', expires: -1});
        events.publish(events.authentication.LOGOUT);

        // if there is afterLogoutRedirect, we will go to there
        if (AccountManager.afterLogoutRedirect) {
            window.location = AccountManager.afterLogoutRedirect;
        } else {
            events.publish(events.authentication.LOGOUT_LEGACY, [{}]);
        }
    };

    var handleOnLoad = function () {
        var accountCookieString = UserService.getUser();
        var sapIdLoginCookieString = $.cookie(sapIdLoginCookieName);
        // the cookies can be different if the user keeps two tabs (one login to ChinaPresale and the other to market site)
        if (accountCookieString && sapIdLoginCookieString && (accountCookieString === sapIdLoginCookieString)) {
            window.sapIdLogin.user = JSON.parse(accountCookieString);
            $.publish(events.authentication.VALID, [sapIdLogin.user]);

            // In order for the shop stuff to work, we need to publish INITIALIZATION_COMPLETE
            AccountManager.currentData = window.sapIdLogin.user;
            //nuskin.account.currentData.eid = user.eid;
            account.initialized = true;
        }
        $.setValue(events.account.INIT_COMPLETE);
    };

    var isLoggedIn = function () {
        // need to check for both cookie to make sure we really login
        var accountCookieString = UserService.getUser();
        var sapIdLoginCookieString = $.cookie(sapIdLoginCookieName);
        return (accountCookieString && sapIdLoginCookieString && (accountCookieString === sapIdLoginCookieString));
    };

    return {
        validateUrl: "http://www.nuskin.com/account-service/api/account/simple/",
        qualifyingUrl: "http://awsprod.nuskin.com/lookup/api/qualification/validate",
        event: "ChinaPresales",
        authenticate: authenticate,
        logout: logout,
        handleOnLoad: handleOnLoad,
        isLoggedIn: isLoggedIn,
        user: {},
        sapId: "",
        email: ""
    };
})();

// subscribe the INVALID_LOGIN and INVALID_EMAIL
$.subscribe(
    events.authentication.INVALID_LOGIN,
    function (object) {
        window.showLoginPopup();
        $('#sapIdLogInForm-invalidLogin').css('display', 'block');
    }
);
$.subscribe(
    events.authentication.INVALID_EMAIL,
    function (object) {
        window.showLoginPopup();
        $('#sapIdLogInForm-invalidEmail').css('display', 'block');
    }
);

