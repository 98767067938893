import {PolymerElement} from '@polymer/polymer/polymer-element.js';
import {html} from '@polymer/polymer/lib/utils/html-tag.js';

const $_documentContainer = document.createElement('div');
$_documentContainer.setAttribute('id', 'customGridStyles');
$_documentContainer.setAttribute('style', 'display: none;');

$_documentContainer.innerHTML = `
<custom-style>
  <style>
    html {
      --grid-container-12: {
        display: grid;
        grid-template-columns: repeat(12, [col] 1fr);
        grid-gap: 0 30px;
      };
      --grid-container-10: {
        display: grid;
        grid-template-columns: repeat(10, [col] 1fr);
        grid-gap: 0 30px;
        position: relative;
      };
      --grid-container-4: {
        display: grid;
        grid-template-columns: repeat(4, [col] 1fr);
        grid-gap: 0 30px;
        position: relative;
      };
      --grid-container-2: {
        display: grid;
        grid-template-columns: repeat(2, [col] 1fr);
        grid-gap: 0 30px;
        position: relative;
      };
      --grid-container-fit: {
        display: grid;
        grid-template-columns: repeat(auto-fit, [col] minmax(333px, 1fr));
      };
      --grid-container-boxed: {
        display: grid;
        grid-template-columns: 1fr [main] minmax(50px, 1366px) 1fr;
      };

      /* TODO: more rows options */
      --grid-rows-auto: {
        grid-auto-rows: minmax(100px, auto);
      }
      --grid-rows-2: {
        grid-template-rows: repeat(5, [row] auto);
      };
      --grid-rows-3: {
        grid-template-rows: repeat(5, [row] auto);
      };
      --grid-rows-4: {
        grid-template-rows: repeat(5, [row] auto);
      };
      --grid-rows-5: {
        grid-template-rows: repeat(5, [row] auto);
      };

      --grid-overlay: {
        display: grid;
        grid-template-columns: repeat(12, [col] 1fr);
        grid-template-rows: minmax(100%, auto);
        grid-gap: 0 30px;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        padding: 0;
      };
      --grid-overlay-item: {
        border: 1px solid;
        background: black;
        opacity: 0.2;
      };
    }
  </style>
</custom-style>
`;

document.head.appendChild($_documentContainer);